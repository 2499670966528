<template>
  <v-footer
    :app="!$store.state.bot_token"
    inset
    color="transparent"
    absolute
    height="56"
    :class="(!$store.state.bot_token ? '' : 'text-body-2 text-center')"
  >
    <div class="w-full">
      <div class="">
          <span>
            &copy;{{ new Date().getFullYear() }} <a
            href="https://www.masiya.net/"
            class="text-decoration-none"
            target="_blank"
          >Masiya Group</a></span>
      </div>
    </div>
  </v-footer>
</template>

<script>
export default {
name: "Footer"
}
</script>

<style scoped>

</style>
