<template>
  <div>
    <v-dialog
      v-model="show_errors"
      width="700"
    >
      <v-card>
        <div class="pa-5" v-if="Errors.length > 0">
          <div class="text-center mb-0">
            <v-img contain width="500" height="250" class="mx-auto" src="/fix-robot.png"></v-img>
          </div>
          <h2 class="mb-8 text-center">Something wrong, reload the page and try again</h2>
          <div v-for="(error, key) in Errors" :key="key">
            <h4 class="text-uppercase">{{error.name}}</h4>
            <ul style="list-style: none;">
              <li v-for="(sub, skey) in error.errors" :key="skey" >{{sub}}</li>
            </ul>
          </div>
          <v-spacer />
          <div class="text-right mt-8">
            <v-btn
              color="primary"
              @click="show_errors = false"
            >
              Close
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "Error",
  data() {
    return {
      show_errors: false
    }
  },
  computed: {
    Errors(){
      let errs = []
      if(this.$store.state.errors){
        const propertyNames = Object.keys(this.$store.state.errors);
        propertyNames.forEach((ErrorKey, index) => {
          let errors = []
          this.$store.state.errors[ErrorKey].forEach((error, i) => {
            errors.push(error)
          })
          errs.push({
            name: ErrorKey,
            errors
          })
        })
      }
      this.show_errors = errs.length > 0
      return errs
    }
  }
}
</script>

<style scoped>

</style>
