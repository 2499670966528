<template>
  <div>
    <v-dialog
      v-model="$store.state.loading"
      persistent
      width="500"
    >
      <v-card class="pa-10">
        <div class="text-center">
          <lottie-player src="/lotties/loading.json" background="transparent" style="width: 350px;height: 200px;" speed="1.2" class="mx-auto" loop autoplay></lottie-player>
          <h2>
            <v-progress-circular
              :size="25"
              color="primary"
              indeterminate
            ></v-progress-circular>
            Please wait...
          </h2>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
name: "Loading"
}
</script>

<style scoped>

</style>
