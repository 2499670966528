import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    access: null,
    bot_token: null,
    loading: false,
    errors: [],
    menu:[
      {
        title: 'Chat Bots',
        to: 'bots',
        icon: 'mdiRobotHappyOutline',
      },
      {
        title: 'Agents Control',
        to: 'agents-control',
        icon: 'mdiFaceAgent',
      }
    ],
    bot_menu: [
      {
        title: 'Control Details',
        to: 'control-details',
        icon: 'mdiCogOutline',
      },
      {
        title: 'Conversation Studio',
        to: 'conversation-studio',
        icon: 'mdiSitemap',
      },
      {
        title: 'View Analytics',
        to: 'view-analytics',
        icon: 'mdiChartLineStacked',
      },
      {
        title: 'Languages',
        to: 'languages',
        icon: 'mdiTranslate',
      },
      {
        title: 'Dialogs',
        to: 'dialogs',
        icon: 'mdiCardText',
      },
      {
        title: 'Intents',
        to: 'intents',
        icon: 'mdiBrain',
      },
    ]
  },
  mutations: {
    SetUser(state, payload) {
      if (payload === null) {
        localStorage.removeItem('user')
        state.user = null
      } else {
        state.user = payload
        localStorage.setItem('user', JSON.stringify(payload))
      }
    },
    SetAccess(state, payload) {
      if (payload === null) {
        localStorage.removeItem('access')
        state.access = null
      } else {
        state.access = payload
        localStorage.setItem('access', JSON.stringify(payload))
      }
    },
  },
  actions: {},
  modules: {},
})
