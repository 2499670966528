import Vue from 'vue'
import VueRouter from 'vue-router'
import middlewarePipeline from '@/middleware/middlewarePipeline'
import guest from '@/middleware/guest'
import auth from '@/middleware/auth'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'bots',
  },
  {
    path: '/bots',
    name: 'bots',
    component: () => import('@/views/Bots/Index.vue'),
    meta: {
      title: 'Chat Bots',
      middleware: [auth],
    },
  },
  {
    path: '/bots/:token',
    name: 'bot-details',
    component: () => import('@/views/Bots/Pages/Home.vue'),
    meta: {
      title: 'Bot Details',
      middleware: [auth],
    },
    children: []
  },
  {
    path: '/agents-control',
    name: 'agents-control',
    component: () => import('@/views/AgentsControl/Index.vue'),
    meta: {
      title: 'Agents Control',
      middleware: [auth],
    },
  },
  {
    path: '/agents-control-chat/:token',
    name: 'agents-control-chat',
    component: () => import('@/views/AgentsControl/PublicIndex.vue'),
    meta: {
      title: 'Agents Control',
      middleware: [auth],
      layout: 'blank',
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/Auth/Profile.vue'),
    meta: {
      title: 'Profile',
      middleware: [auth],
    },
  },
  {
    path: '/auth/login',
    name: 'login',
    component: () => import('@/views/Auth/Login.vue'),
    meta: {
      title: 'Login',
      middleware: [guest],
      layout: 'blank',
    },
  },
  {
    path: '/auth/autologin',
    name: 'autologin',
    component: () => import('@/views/Auth/AutoLogin.vue'),
    meta: {
      title: 'Auto Login',
      layout: 'blank',
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

// eslint-disable-next-line no-unused-vars
router.afterEach((to, from) => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next()
  }
  const { middleware } = to.meta

  const context = {
    to,
    from,
    next,
    store,
  }

  return middleware[0]({
    context,
    next: middlewarePipeline(context, middleware, 1),
  })
})


export default router
