<template>
  <v-menu
    offset-y
    left
    nudge-bottom="14"
    min-width="230"
    content-class="user-profile-menu-content"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-avatar
        size="40px"
        v-bind="attrs"
        v-on="on"
      >
        <v-img src="/avatar.png"></v-img>
      </v-avatar>
    </template>
    <v-list>
      <div class="pb-3 pt-2 px-5">
        <v-avatar size="40px">
          <v-img src="/avatar.png"></v-img>
        </v-avatar>
        <div
          v-if="$store.state.user"
          class="d-inline-flex flex-column justify-center ms-3"
          style="vertical-align:middle"
        >
          <span class="text--primary font-weight-semibold mb-n1">
            {{ $store.state.user.full_name }}
          </span>
          <small class="text--disabled text-capitalize">
            {{ $store.state.user.email }}
          </small>
        </div>
      </div>

      <v-divider></v-divider>

      <!-- Profile -->
      <v-list-item
        link
        @click="$router.push({ name: 'profile'})"
      >
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiAccountOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Profile</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="my-2"></v-divider>

      <!-- Logout -->
      <v-list-item
        link
        @click="logout"
      >
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiLogoutVariant }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import {
  mdiAccountOutline,
  mdiEmailOutline,
  mdiCheckboxMarkedOutline,
  mdiChatOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
} from '@mdi/js'

export default {
  setup() {
    return {
      icons: {
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCheckboxMarkedOutline,
        mdiChatOutline,
        mdiCogOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
      },
    }
  },
  methods: {
    logout() {
      this.$store.state.loading = true
      this.$http({
        method: 'delete',
        url: '/v1/auth/logout',
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json',
          Authorization: `${this.$store.state.access.token_type} ${this.$store.state.access.token}`,
        },
      }).finally(() => {
        this.$store.commit('SetUser', null)
        this.$store.commit('SetAccess', null)
        this.$store.state.loading = false
        this.$router.push({ name: 'login' })
      })
    },
  },
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
