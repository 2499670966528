<template>
  <v-app>
    <v-main>
      <router-view></router-view>
    </v-main>
    <Loading />
    <Error />
  </v-app>
</template>

<script>
import Loading from "@/layouts/Loading";
import Error from "@/layouts/Error";
export default {
  components:{
    Loading,
    Error
  }
}
</script>

<style>
</style>
