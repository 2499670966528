<template>
  <v-navigation-drawer
    v-if="$store.state.bot_token"
    v-model="bot_drawer"
    app
    floating
    width="250"
    class="ml-14"
    permanent
  >

    <v-sheet width="100%" class="pa-5">
      <router-link
        to="/"
        class="text-decoration-none w-full"
      >
        <v-img
          src="/logo.png"
          contain
          alt="logo"
          class="mx-auto"
        />
        <v-slide-x-transition>
          <h5 class="text--primary text-center text-uppercase mt-2">
            Chatbot Builder
          </h5>
        </v-slide-x-transition>
      </router-link>
    </v-sheet>
    <v-divider class="mb-2"/>

    <v-list
      rounded
      link
      class="vertical-nav-menu-items"
    >
      <v-list-item
        v-for="(list, key) in $store.state.bot_menu"
        :key="key"
        class="vertical-nav-menu-link"
        v-bind="$attrs"
        @click="$router.push({name: list.to})"
      >
        <v-list-item-icon>
          <v-icon
            size="20"
            :class="{'alternate-icon-small': !icons[list.icon]}"
            class="mx-auto"
          >
            {{ icons[list.icon] }}
          </v-icon>
        </v-list-item-icon>

        <v-list-item-title class="pb-1">
          {{ list.title }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
    <Footer v-if="$store.state.bot_token"/>
  </v-navigation-drawer>
</template>

<script>
import Footer from "@/layouts/Footer";
import {mdiCogOutline, mdiSitemap, mdiTranslate, mdiCardText, mdiBrain, mdiChartLineStacked} from "@mdi/js"

export default {
  name: "SubVerticalNavMenu",
  components: {
    Footer
  },
  data() {
    return {
      bot_drawer: true,
      icons: {
        mdiCogOutline,
        mdiSitemap,
        mdiTranslate,
        mdiCardText,
        mdiBrain,
        mdiChartLineStacked
      }
    }
  },
}
</script>

<style scoped>

</style>
