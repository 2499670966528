<template>
  <div>
    <v-navigation-drawer
      :value="isDrawerOpen"
      app
      :floating="!mini"
      :permanent="mini"
      :mini-variant="mini"
      :right="$vuetify.rtl"
      @input="val => $emit('update:is-drawer-open', val)"
    >
      <!-- Navigation Header -->
      <div class="vertical-nav-header d-flex items-center pb-5">
        <router-link
          v-if="!mini"
          to="/"
          class="text-decoration-none w-full pt-16"
        >
          <v-img
            src="/logo.png"
            max-height="200px"
            max-width="200px"
            contain
            alt="logo"
            class="pa-0 mx-auto"
          ></v-img>
          <v-slide-x-transition>
            <h4
              style="margin-top: 15px;"
              class="text--primary text-center text-uppercase"
            >
              Chatbot Builder
            </h4>
          </v-slide-x-transition>
        </router-link>
      </div>

      <!-- Navigation Items -->
      <v-list
        expand
        :rounded="!mini"
        class="vertical-nav-menu-items"
      >
        <!--      <nav-menu-section-title title="Main Menu"></nav-menu-section-title>-->
        <nav-menu-link
          v-for="(list, key) in $store.state.menu"
          :key="key"
          class="mb-4"
          :title="list.title"
          :to="{ name: list.to }"
          :icon="icons[list.icon]"
        ></nav-menu-link>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import {
  mdiHomeOutline,
  mdiRobotHappyOutline,
  mdiFaceAgent,
} from '@mdi/js'
import NavMenuSectionTitle from './components/NavMenuSectionTitle.vue'
import NavMenuLink from './components/NavMenuLink.vue'

export default {
  components: {
    NavMenuSectionTitle,
    NavMenuLink,
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    '$store.state.bot_token': function () {
      this.mini = (!!this.$store.state.bot_token)
    },
  },
  mounted() {
    this.mini = (!!this.$store.state.bot_token)
  },
  data() {
    return {
      bot_drawer: true,
      items: [
        {title: 'Home', icon: 'mdi-view-dashboard'},
        {title: 'About', icon: 'mdi-forum'},
      ],
      mini: false,
      icons: {
        mdiRobotHappyOutline,
        mdiHomeOutline,
        mdiFaceAgent
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;

  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using($material) {
  background-color: map-deep-get($material, 'background');
}

.app-navigation-menu {
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}
</style>
