<template>
  <div>
    <v-app>
      <vertical-nav-menu :is-drawer-open.sync="isDrawerOpen"></vertical-nav-menu>

      <v-app-bar
        app
        flat
        absolute
        height="50"
        color="transparent"
      >
        <div class="w-full" :class="(!$store.state.bot_token ? 'box-container' : '')" style="margin-top: 15px">
          <div :class="(!$store.state.bot_token ? 'mx-6' : 'mx-3 pl-15')" class="d-flex align-center">
            <!-- Left Content -->
            <v-app-bar-nav-icon
              v-if="!$store.state.bot_token"
              class="d-block d-lg-none me-2"
              @click="isDrawerOpen = !isDrawerOpen"
            ></v-app-bar-nav-icon>
            <h3>{{$router.currentRoute.meta.title}}</h3>

            <v-spacer></v-spacer>
            <theme-switcher class="mx-5"></theme-switcher>
            <app-bar-user-menu></app-bar-user-menu>
          </div>
        </div>
      </v-app-bar>

      <v-main>
        <div :class="(!$store.state.bot_token ? 'app-content-container boxed-container pa-6' : 'pa-3 pl-16')">
          <SubVerticalNavMenu />
          <div :class="($store.state.bot_token ? 'px-2' : '')">
            <slot></slot>
          </div>
        </div>
      </v-main>
      <Footer v-if="!$store.state.bot_token" />
    </v-app>
    <Loading />
    <Error />
  </div>
</template>

<script>
import Loading from "@/layouts/Loading";
import Error from "@/layouts/Error";
import { mdiMagnify, mdiBellOutline, mdiGithub } from '@mdi/js'
import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
import ThemeSwitcher from './components/ThemeSwitcher.vue'
import AppBarUserMenu from './components/AppBarUserMenu.vue'
import SubVerticalNavMenu from "@/layouts/components/SubVerticalNavMenu";
import Footer from "@/layouts/Footer";
export default {
  components: {
    VerticalNavMenu,
    ThemeSwitcher,
    AppBarUserMenu,
    SubVerticalNavMenu,
    Footer,
    Loading,
    Error
  },
  data() {
    return {
      isDrawerOpen: true,

      // Icons
      icons: {
        mdiMagnify,
        mdiBellOutline,
        mdiGithub,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.v-app-bar ::v-deep {
  .v-toolbar__content {
    padding: 0;

    .app-bar-search {
      .v-input__slot {
        padding-left: 18px;
      }
    }
  }
}

.boxed-container {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}
</style>
