export default function guest({ next }) {
  const access = localStorage.getItem('access')

  if (access) {
    return next({
      name: 'bots',
    })
  }

  return next()
}
