<template>
  <component :is="resolveLayout">
    <router-view></router-view>
  </component>
</template>

<script>
import { computed } from '@vue/composition-api'
import { useRouter } from '@/utils'
import LayoutBlank from '@/layouts/Blank.vue'
import LayoutContent from '@/layouts/Content.vue'

export default {
  components: {
    LayoutBlank,
    LayoutContent,
  },
  watch: {
    '$route': function () {
      this.$store.state.bot_token = this.$route.params.token
    },
  },
  mounted() {
    let access = localStorage.getItem('access')
    let user = localStorage.getItem('user')
    if (access) {
      access = JSON.parse(access)
      this.$store.commit('SetAccess', access)
    }
    if (user) {
      user = JSON.parse(user)
      this.$store.commit('SetUser', user)
    }
  },
  setup() {
    const { route } = useRouter()

    const resolveLayout = computed(() => {
      // Handles initial route
      if (route.value.name === null) return null

      if (route.value.meta.layout === 'blank') return 'layout-blank'

      return 'layout-content'
    })

    return {
      resolveLayout,
    }
  },
}
</script>
